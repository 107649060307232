/* eslint-disable camelcase */

import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import React from 'react'
import { Alert, Badge, Button } from 'react-bootstrap'
import { useGetListingQuery } from '../../../../../../components/api'
import { useGetListingLotSkusQuery } from '../../../../../../components/api/BuyerApi'
import Layout from '../../../../../../components/Layout'
import ListingStatus from '../../../../../../components/ListingStatus'
import MUIDataTable from '../../../../../../components/MuiDatatableUtils'
import Spinner from '../../../../../../components/spinner'
import SubmitUnitPriceModal from '../../../../../../pages/app/kitchensink/buyerSubmitUnitPriceModal'
import UploadUnitPriceModal from '../../../../../../pages/app/kitchensink/buyerUploadUnitPriceModal'

// ANCHOR Page
const Page = (props) => {
  // ANCHOR props
  const { listingId, listingLotId } = props
  // ANCHOR queries
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const {
    data: {
      global_listing_number = '',
      listing_lots: listingLots = []
    } = {}
  } = listingQuery
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId, listingLots, isVendor: false }) // N.B. from this page, we are always the buyer!
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot
  const areUnitPricesIncomplete = !listingLotSkusQuery.isSuccess || listingLotSkus.some(sku => sku.unit_price === null)
  // ANCHOR state
  const [openUploadModal, setOpenUploadModal] = React.useState(false)
  const [openSubmitModal, setOpenSubmitModal] = React.useState(false)

  // ANCHOR React
  return <>
    <Layout>

        <Layout.Title>
            <div className="d-flex align-items-center">
              <h5>List Number: {global_listing_number}</h5>
              { listingQuery.isSuccess && <ListingStatus status={listingQuery.data.status} /> }
            </div>
        </Layout.Title>

        <Layout.CardGrey>

        <div className="card-body rounded-soft bg-white">
          <div className="rh-bodyheader">Unit Price Request - Lot {listingLotSkusQuery.isSuccess ? listingLot.listing_lot_number : '-'}</div>

          {listingLot.unit_price_submission_date && <Badge pill variant="outline-dark" className="float-right">Last Submitted: {moment(listingLot.unit_price_submission_date).format('YY-MM-DD HH:mm')}</Badge>}

          <p>Submit your unit prices via bulk upload.</p>

          { listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div> }
          { listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error: ' + listingLotSkusQuery.error.message}</Alert> }
          { listingLotSkusQuery.isSuccess && <MUIDataTable
                    columns={[
                      { label: 'Lot', name: 'listing_lot_number', options: { customBodyRender: () => listingLot.listing_lot_number } },
                      { label: 'Description', name: 'sku_description' },
                      { label: 'Make', name: 'make', options: { display: false } },
                      { label: 'Model', name: 'model', options: { display: false } },
                      { label: 'Memory', name: 'memory', options: { display: false } },
                      { label: 'Colour', name: 'colour', options: { display: false } },
                      { label: 'Carrier', name: 'carrier', options: { display: false } },
                      { label: 'Id lock', name: 'id_lock', options: { display: false } },
                      { label: 'Kit', name: 'kit', options: { display: false } },
                      { label: 'Region lock', name: 'region_lock', options: { display: false } },
                      { label: 'Sim lock', name: 'sim_lock', options: { display: false } },
                      { label: 'Grade', name: 'grade' },
                      { label: 'Quantity', name: 'qty' },
                      { label: 'Unit price', name: 'unit_price', options: { customBodyRender: (value) => value ? ('$' + parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2 })) : '' } },
                      { label: 'Subtotal', name: '_subtotal', options: { customBodyRender: (value) => value ? ('$' + (value).toLocaleString(undefined, { maximumFractionDigits: 2 })) : '' } }
                    ]}
                    data={listingLotSkus}
                    options={{
                      pagination: true,
                      jumpToPage: false,
                      print: false,
                      search: true,
                      download: true,
                      sort: true,
                      filter: true,
                      viewColumns: true,
                      selectableRows: 'none',
                      elevation: 0,
                      rowsPerPage: 100,
                      setTableProps: () => ({ size: 'small' }),
                      customToolbar: () => {
                        return (
                          <>
                            <Tooltip title={'Bulk Upload'}>
                              <Button variant="outline-primary" size="sm" className="ml-2" onClick={() => setOpenUploadModal(true)} disabled={!!listingLot.unit_price_submission_date}>Bulk Upload</Button>
                            </Tooltip>
                            <Tooltip title={areUnitPricesIncomplete ? 'Please enter a unit price for all line items before submitting.' : ''}>
                              <Button variant="primary" size="sm" className="ml-2" onClick={() => setOpenSubmitModal(true)} disabled={!!listingLot.unit_price_submission_date}>Submit</Button>
                            </Tooltip>
                          </>)
                      }
                    }}
                  />
            }

        {/* ANCHOR Debug */}
        { process.env.NODE_ENV !== 'production' && <pre>listingId={JSON.stringify(listingId, null, 2)}</pre>}
        { process.env.NODE_ENV !== 'production' && <pre>listingLotId={JSON.stringify(listingLotId, null, 2)}</pre>}
        { process.env.NODE_ENV !== 'production' && <pre>areUnitPricesIncomplete={JSON.stringify(areUnitPricesIncomplete, null, 2)}</pre>}
        { process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkusQuery, null, 2)}</pre>}
        </div>
        </Layout.CardGrey>

    </Layout>

    <UploadUnitPriceModal
      openModal={openUploadModal}
      setOpenModal={setOpenUploadModal}
      listingId={listingId}
      listingLotId={listingLotId}
      />

    <SubmitUnitPriceModal
      openModal={openSubmitModal}
      setOpenModal={setOpenSubmitModal}
      listingId={listingId}
      listingLotId={listingLotId}
      areUnitPricesIncomplete={areUnitPricesIncomplete}
      />

    </>
}

export default Page
